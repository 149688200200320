.slider {
  position: relative;
  width: 100%;
  height: 400px;
  overflow: hidden;
}

.slider img { 
  position: absolute;
  top: 0;
  right: 0;
  width: auto;
  height: 100%;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

@media (min-width: 640px) {
  .slider img { 
    position: absolute;
    top: 0;
    right: 0;
    width: auto;
    height: 100%;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
    transform: translateX(-50%);
  }
}

.slider img.active {
  opacity: 1;
}